import '../styles/elements/popup.scss'
function Popup({children, openedFlag, setOpenedFlag}) {

    return (
        <div className={
            'popup-background ' +
            ((!!openedFlag) ? '' : 'popup-hide')
        }>
            <div className={
                'popup'
            }>
                <div className={'popup-header'}>
                    <div className={'popup-close'}>
                        <button onClick={() => setOpenedFlag(false)}>
                            ✖
                        </button>
                    </div>
                </div>
                <div className={'popup-data'}>
                    <div className={'popup-data-inner'}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popup;