import s from '../styles/pages/main.module.scss'
import DefaultInput from "../elements/inputs/DefaultInput";
import BitrixUserSelector from "../elements/inputs/BitrixUserSelector";
import SubmitButton from "../elements/inputs/SubmitButton";
import InlineNotification from "../elements/InlineNotification";
import Header from "../elements/Header";
import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import getAPIClient from "../hooks/apiClient";
import OneSelectUI from "../elements/inputs/one-select";
import Popup from "../elements/popup";
import AllFieldsSelector from "../elements/fields-selector/AllFieldsSelector";
import moment from "moment";
import getAPIClientMocked from "../hooks/apiClientMocked";

import {ReactComponent as CopySVG} from '../assets/copy.svg';
import InlineNotificationWithContent from "../elements/InlineNotificationWithContent";


export default function MainPage() {
    const [appInitialized, setAppInitialized] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    // Флаги, которые говорят что юзер что-то поменял после последнего получения данных
    const [spUpdatedFlag, setSpUpdatedFlag] = useState(false)
    const [mappingUpdatedFlag, setMappingUpdatedFlag] = useState(false)

    const [fieldsPopupSelectorOpenedFlag, setFieldsPopupSelectorOpenedFlag] = useState(false)

    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState('error')

    const BX24 = useMemo(() => window.BX24, [])
    BX24.init(() => setAppInitialized(true));

    const api = useMemo(() => getAPIClient(BX24), [appInitialized])

    // закомментировать, это мок
    // const api = useMemo(getAPIClientMocked, [])

    // Смарт процессы
    const [smartProcessesList, setSmartProcessesList] = useState([])
    useEffect(() => {
        if (!appInitialized) return

        api.get('/setup/smart_processes_from_portal').then(response => {
            let sp_list = []
            for (let i in response.data) {
                let sp = response.data[i]
                sp.label = sp.title
                sp.value = sp.local_smart_process_id
                sp_list.push(sp)
            }
            setSmartProcessesList(sp_list)
        }).finally(setIsLoading(false))
    }, [api])

    const [selectedSmartProcess, setSelectedSmartProcess] = useState(undefined)

    // Список отношений (данные -> поле)
    const [dataToFieldMapping, setDataToFieldMapping] = useState([])
    useEffect(() => {
        if (selectedSmartProcess === undefined) return
        setIsLoading(true)
        api.get(
            `/portal_fields_mappings?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}`
        ).then(response => {
            let mapping_list = []
            for (let i in response.data) {
                let mapping_item = response.data[i]
                mapping_item.label = mapping_item.ui_name
                mapping_item.value = mapping_item.system_name
                mapping_list.push(mapping_item)
            }
            setDataToFieldMapping(mapping_list)
        }).finally(() => setIsLoading(false))
    }, [selectedSmartProcess])

    // Список доступных полей на портале
    const [availableFieldsInSmartProcess, setAvailableFieldsInSmartProcess] = useState([])
    useEffect(() => {
        if (selectedSmartProcess === undefined) return
        api.get(
            `/setup/fields_smart_process?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}`
        ).then(response => {
            let fields_list = []
            for (let i in response.data) {
                let field = response.data[i]
                field.label = field.field_name
                field.value = field.b24_system_name
                fields_list.push(field)
            }
            setAvailableFieldsInSmartProcess(fields_list)
        })
    }, [selectedSmartProcess])

    // Если где-то в маппинге указано б24 поле, которого не существует в списке полей с бэка,
    // то удаляем это поле в маппинге
    const [wrongMappingClearedFlag, setWrongMappingClearedFlag] = useState(false)

    useEffect(() => {
        console.log('called', wrongMappingClearedFlag, dataToFieldMapping, availableFieldsInSmartProcess)

        if (dataToFieldMapping.length === 0 || availableFieldsInSmartProcess.length === 0) {
            return
        }
        if (wrongMappingClearedFlag) {
            return
        }
        setWrongMappingClearedFlag(true)

        let copiedDataToFieldMapping = JSON.parse(JSON.stringify(dataToFieldMapping))
        for (let i in copiedDataToFieldMapping) {
            let selectedField = availableFieldsInSmartProcess.filter(
                (field) => copiedDataToFieldMapping[i].b24_system_name === field.b24_system_name
            )[0]

            if (selectedField === undefined) {
                copiedDataToFieldMapping[i]['b24_system_name'] = null
            }
        }
        setDataToFieldMapping(copiedDataToFieldMapping)
    }, [dataToFieldMapping, availableFieldsInSmartProcess])


    // Счетчик для отображения на кнопке
    const currentConfiguredFieldsCounter = useMemo(() => {
        let counter = 0;
        for (let i in dataToFieldMapping) {
            if (dataToFieldMapping[i].b24_system_name !== null) {
                counter += 1
            }
        }
        return counter
    }, [dataToFieldMapping])


    const [globalNotificationMessage, setGlobalNotificationMessage] = useState('')
    const [globalNotificationType, setGlobalNotificationType] = useState('error')

    // Получение конфига, установка текущего СП
    useEffect(() => {
        if (smartProcessesList === undefined || smartProcessesList.length === 0)
            return

        api.get(`/bitrix_portals/settings`).then(response => {
            let currentSP = JSON.parse(JSON.stringify(smartProcessesList.filter(
                (el) => response.data.local_smart_process_id === el.local_smart_process_id
            )[0]))
            setSelectedSmartProcess(currentSP)
        })
    }, [smartProcessesList])

    // Обновление конфигурации приложения (смарт-процесс, маппинги)
    const saveChanges = useCallback(async () => {
        let errorText = ''
        setNotificationMessage('')

        // отправка запрос на обновление settings
        if (spUpdatedFlag) {
            try {
                await api.post(`/bitrix_portals/settings`, selectedSmartProcess)
                setSpUpdatedFlag(false)
            } catch (e) {
                errorText = 'Ошибка при обновлении выбранного СП; '
            }
        }

        // Отправляем запрос на обновление mapping
        if (mappingUpdatedFlag) {
            try {
                await api.put(`/portal_fields_mappings`, dataToFieldMapping)
                setMappingUpdatedFlag(false)
            } catch (e) {
                errorText = errorText + 'Ошибка при обновлении маппинга данных к полям'
            }
        }

        // Дорабатываем сообщение с ошибкой, если таковые имеются
        if (errorText.length !== 0) {
            errorText += '. Мы уже получили информацию об ошибках и исправим их в течение 1 рабочего дня. Так же дополнительно вы можете написать в тех. поддержку, нажав на кнопку "Чат поддержки"'
            setNotificationType('error')
            setNotificationMessage(errorText)
        } else {
            setNotificationType('success')
            setNotificationMessage('Данные успешно обновлены')
        }
    }, [selectedSmartProcess, dataToFieldMapping, spUpdatedFlag, mappingUpdatedFlag])

    // ------ Работа с токенами ------

    const [tokensPopupOpenedFlag, setTokensPopupOpenedFlag] = useState(false)
    const [tokens, setTokens] = useState([])

    // --- Взаимодействие с апи

    // Функция для получения списка токенов
    const apiFetchTokens = useCallback(async () => {
        let response = await api.get(`/extension_tokens`)
        return response.data
    }, [api])

    // Функция для удаления токена
    const apiDeleteToken = useCallback(async (token_id) => {
        await api.delete(`/extension_tokens/${token_id}`)
    }, [api])

    // Функция для создания токена
    const apiCreateToken = useCallback(async (token_name) => {
        let data = {
            token_name: token_name
        }
        let response = await api.post(`/extension_tokens`, data)
        return response.data
    }, [api])

    // --- Функции для всего процесса получения, изменения, удаления токенов

    // Первичное получение всех токенов
    useEffect(() => {
        if (!appInitialized) return

        apiFetchTokens().then(fetchedTokens => setTokens(fetchedTokens.items))
    }, [apiFetchTokens])

    // Удаление токена
    const deleteToken = useCallback(async (token_id) => {
        await apiDeleteToken(token_id)
        let fetchedTokens = await apiFetchTokens()
        setTokens(fetchedTokens.items)
    }, [apiDeleteToken, apiFetchTokens, setTokens])

    // Создание токена
    const [newTokenName, setNewTokenName] = useState('')
    const createToken = useCallback(async () => {
        setNewTokenName('')
        await apiCreateToken(newTokenName)
        let fetchedTokens = await apiFetchTokens()
        setTokens(fetchedTokens.items)
    }, [apiCreateToken, newTokenName])


    return (
        <>
            <Loading isLoading={(!appInitialized) || isLoading}/>
            <Header/>
            <div className={s.main}>
                <InlineNotificationWithContent
                    type={'info'}
                    hideFlag={false}
                >
                    Мы выпустили новую версию ResumeScan! Теперь приложение имеет ещё больше функций и возможностей для HR-специалистов.<br/>
                    <br/>
                    Основное в обновлении: возможность автоматического занесения откликов с hh.ru, встроенный чат с соискателем в карточке CRM и встроенная синхронизация данных по клику в карточке и в резюме соискателя на hh.ru<br/>
                    <br/>
                    Текущая версия ResumeScan останется доступной, но не будет обновляться.<br/>
                    Рекомендуем перейти на новую версию ResumeScan, чтобы получить доступ к новым функциям.<br/>
                    <br/>
                    Ссылка на новую версию ResumeScan: <a target={'_blank'} href={'https://www.bitrix24.ru/apps/app/webformat.resumescan/'}>https://www.bitrix24.ru/apps/app/webformat.resumescan/</a><br/>
                    Спасибо за использование ResumeScan!
                </InlineNotificationWithContent>
                <div className={s.main_container}>
                    <div className={s.settings}>
                        <h1>Общие настройки</h1>
                        <OneSelectUI
                            title={'Выберите, куда сохранить резюме'}
                            selectionOptions={smartProcessesList}
                            selectedOption={selectedSmartProcess}
                            setSelectedOption={(selected) => {
                                setSelectedSmartProcess(selected)
                                setSpUpdatedFlag(true)
                            }}
                            placeholder={'Выберите, куда будут попадать резюме'}
                        />
                        <div className={'title'}>
                            Выберите поля в этой сущности, в которые будут заноситься данные
                        </div>
                        <button className={'fields-selector-popup-button'} onClick={
                            () => {
                                setFieldsPopupSelectorOpenedFlag(true)
                                setMappingUpdatedFlag(true)
                            }
                        }>
                            {currentConfiguredFieldsCounter === 0
                                ? 'Настроить сохраняемые данные'
                                : `${currentConfiguredFieldsCounter}/${dataToFieldMapping.length} Соотнесено данных`
                            }
                        </button>
                        <Popup openedFlag={fieldsPopupSelectorOpenedFlag}
                               setOpenedFlag={setFieldsPopupSelectorOpenedFlag}>
                            {fieldsPopupSelectorOpenedFlag && <AllFieldsSelector
                                dataToFieldMapping={dataToFieldMapping}
                                setDataToFieldMapping={setDataToFieldMapping}
                                availableFieldsInSmartProcess={availableFieldsInSmartProcess}
                                comment={'Вы сможете настроить/изменить эти данные позже'}
                            />}
                        </Popup>
                        <SubmitButton
                            onClick={saveChanges}
                            disabled={!mappingUpdatedFlag && !spUpdatedFlag}
                            className={s.submit}
                            text={'Сохранить'}
                        />
                        <InlineNotification
                            type={notificationType}
                            text={notificationMessage}
                            hideFlag={notificationMessage.length === 0}
                        />
                        <h1>Работа с расширением</h1>
                        <button
                            className={s.download_extension_button}
                            onClick={() => window.open(
                                'https://chromewebstore.google.com/detail/resumescan/cadadmdnamampppdldkkodghcjdfgihf',
                                '_blank'
                            )}
                        >
                            Скачать расширение
                        </button>
                        <Popup openedFlag={tokensPopupOpenedFlag} setOpenedFlag={setTokensPopupOpenedFlag}>
                            <div className={s.tokens_editor}>
                                <div className={s.tokens_popup_header}>
                                    <h2>Список лицензионных ключей расширения</h2>
                                </div>
                                <div className={s.tokens_popup_add_row}>
                                    <DefaultInput
                                        className={s.tokens_popup_add_row_input}
                                        title={'Введите название для нового токена'}
                                        value={newTokenName}
                                        onChangeFunction={(e) => setNewTokenName(e.target.value)}
                                    />
                                    <SubmitButton
                                        onClick={createToken}
                                        text={'+'}
                                    />
                                </div>
                                <table className={s.tokens_list}>
                                    <thead className={'tokens-list-header'}>
                                    <tr>
                                        <th>Название</th>
                                        <th>Лицензионный ключ</th>
                                        <th>Дата создания</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tokens.map(token => <tr className={'token'}>
                                        <td>{token.token_name}</td>
                                        <td>{token.access_token}</td>
                                        <td>{moment.utc(token.created_at).local().format('HH:mm DD.MM.YYYY')}</td>
                                        <td className={s.token_delete} onClick={() => deleteToken(token.id)}>✖</td>
                                    </tr>)}

                                    </tbody>
                                </table>
                            </div>
                        </Popup>
                        <SubmitButton
                            onClick={() => setTokensPopupOpenedFlag(true)}
                            text={'Работа с токенами'}
                        />

                    </div>
                    <div className={s.lessons}>
                        <div className={s.lesson}>
                            <iframe
                                src="https://www.youtube.com/embed/jXSzwIqOPik"
                                title="ResumeScanner - работа с резюме" frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                        <button className={s.all_lessons_button} onClick={() => window.open(
                            'https://www.youtube.com/watch?v=jXSzwIqOPik&list=PLQReu4zUp5f1LNyxEpAJuCE4FUIpI4IlZ&ab_channel=Вебформат', '_blank')}>
                            Все обучающие материалы
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}