import './styles/main.scss';
import {BrowserRouter, Route, Routes, useSearchParams} from 'react-router-dom';
import {useMemo} from "react";
import InstallPage from "./pages/InstallPage";
import MainPage from "./pages/MainPage";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<MainPage/>} />
                <Route exact path="/install" element={<InstallPage/>} />
            </Routes>
        </BrowserRouter>
    )

}

export default App;
